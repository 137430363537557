.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.service {
  background: var(--color-bg-variant);
  border-radius: 1rem;
  /* border: 1px solid var(--color-primary); */
  height: auto;
  transition: var(--transition);
}

.service:hover {
  /* background: transparent; */
  box-shadow: var(--shadow);
  border-color: var(--color-primary-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 1.5rem;
  border-radius: 0.9rem 0.9rem 0rem 0rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
  display: list-item;
  list-style: disc;
  list-style-position: outside;
}

.service__list-icon {
  color: var(--color-primary);
  /* margin-top: 2px; */
}

.service__list p {
  font-size: 0.9rem;
}


.service__sublist {
  padding: 0.8rem;
}

.service__sublist li {
  display: flex;
  display: list-item;
  list-style: circle;
  list-style-position: outside;
  margin: 0px;
}

.service__sublist p {
  font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .service {
    height: auto;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
